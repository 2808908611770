define("semantic-ui-ember/components/ui-modal", ["exports", "semantic-ui-ember/mixins/base", "semantic-ui-ember/templates/components/ui-modal"], function (_exports, _base, _uiModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_base.default, {
    layout: _uiModal.default,
    module: 'modal',
    classNames: ['ui', 'modal'],
    willInitSemantic: function willInitSemantic(settings) {
      this._super.apply(this, arguments);

      if (settings.detachable == null) {
        settings.detachable = false;
      }

      if (settings.observeChanges == null) {
        settings.observeChanges = true;
      }
    }
  });

  _exports.default = _default;
});