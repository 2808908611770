define("semantic-ui-ember/components/ui-checkbox", ["exports", "semantic-ui-ember/mixins/checkbox", "semantic-ui-ember/templates/components/ui-checkbox"], function (_exports, _checkbox, _uiCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_checkbox.default, {
    layout: _uiCheckbox.default,
    type: 'checkbox',
    ignorableAttrs: ['checked', 'label', 'disabled'],
    // Internal wrapper for onchange, to pass through checked
    _onChange: function _onChange() {
      var checked = this.execute('is checked');
      return this.attrs.onChange(checked, this);
    }
  });

  _exports.default = _default;
});